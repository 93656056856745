/* eslint-disable @typescript-eslint/no-unused-vars */
import InputLine from "../../components/input/inputLine";
import { Blank, CheckBox, Div, EvButton, Font, PageLayout, Row, Span } from "../../styles/commonStyle";
import { cardTitleColor, labelBgColor, noticeBlueColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { InfoBox } from "./style";
import { useNavigate } from "react-router-dom";
import { ResponseModel } from "../../model/response";
import { APILIST, request } from "../../repository/api";
import { useRecoilState } from "recoil";
import { Li, Ul } from "../register/style";
import { useEffect, useState } from "react";
import { getUserIdFromToken, logoutHandler } from "../../util";
import Loading from "../../components/loading/loading";
import { loadingState, loginState } from "../../recoil/atoms";

function WithdrawalPage() {
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const [islogin, setlogin] = useRecoilState(loginState);
  const [isLoading, setLoading] = useRecoilState(loadingState);

  const onClickHandler = async () => {
    if (agree === true) {
      if (!window.confirm("회원 탈퇴를 진행하시겠습니까?")) {
        return;
      }

      let id: string = getUserIdFromToken();
      let response: ResponseModel<any> = await request(APILIST.USER_DELETE + "/" + id, "delete");
      if (response.result === "OK") {
        alert("정상적으로 완료되었습니다.");
        logoutHandler(setlogin, navigate);
      } else {
        if (response.data.validation) {
          alert("[" + response.data.validation.error + "] " + response.data.validation.msg);
        } else {
          alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
        }
      }
    } else {
      alert("탈퇴 규정에 동의해주셔야 탈퇴가 가능합니다.");
    }
  };

  let readData: JSX.Element = (
    <Div>
      <Ul className="list-disc w-full" listPosition="outside">
        <Li>탈퇴 시 6개월간 재가입이 불가합니다.</Li>
        <Li>탈퇴 시 아이디 재사용 및 복구가 불가하오니 신중하게 선택하시기 바랍니다.</Li>
        <Li>
          탈퇴한 아이디는 <Span color={noticeBlueColor}>개인정보처리방침</Span>에 의거하여 회원 정보가 처리됩니다.
        </Li>
      </Ul>
      <Row>
        <label className="flex">
          <CheckBox type="checkbox" className="me-[8px]" checked={agree} onChange={() => setAgree(!agree)} />
          동의합니다
        </label>
      </Row>
    </Div>
  );
  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        회원탈퇴
      </Font>
      <Blank height="2rem" />
      <InfoBox>
        <InputLine label="탈퇴규정" name="role" type="read" necessary={false} readData={readData} labelColor={labelBgColor} />
      </InfoBox>
      <Div className="flex w-[100%] justify-center py-[3rem]">
        <EvButton color="#FFF" bgColor={primaryColor} width="7rem" onClick={() => onClickHandler()}>
          회원탈퇴
        </EvButton>
      </Div>
      {/* <Loading isLoading={isLoading} /> */}
    </PageLayout>
  );
}
export default WithdrawalPage;

import { selector, selectorFamily } from "recoil";
import { ResponseModel } from "../model/response";
import { APILIST, request } from "../repository/api";
import { ResponseList } from "../model/responseList";
import { Notice } from "../model/notice";
import { Stipulation } from "../model/stipulation";
import {
  accessTokenState,
  carCompanyState,
  carState,
  certifyPhoneState,
  chargingState,
  checkAuthState,
  companyUserRegistState,
  findIdState,
  findPwState,
  justMembershipGrade,
  noticeDetailState,
  noticeMainState,
  noticeState,
  platformState,
  refreshTokenState,
  showUserState,
  smartroRoamingState,
  smartroState,
  smatroReigistBillingState,
  userChargingState,
  userExpiresState,
  userMembershipGrade,
  userPointListState,
  userPointState,
  userRegistState,
  variableState,
  popUpState,
  userRepayState,
  userPaidFailState
} from "./atoms";
import { A } from "../styles/commonStyle";
import { object } from "yup";
import { getUserIdFromToken } from "../util";

export const emailSelector = selector({
  key: `emailState`,
  get: async ({ get }) => {
    let res: ResponseModel<any> = await request(APILIST.FINDID, "get", get(findIdState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(findIdState, object);
  },
});

// 게시물 조회
export const noticeSelector = selector({
  key: `noticeSelector`,
  get: async ({ get }) => {
    if (get(noticeState) === undefined) return;
    let res: ResponseModel<ResponseList<Notice>> = await request(APILIST.NOTICE, "get", get(noticeState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(noticeState, object);
  },
});

// 게시물 상세 조회
export const noticeDetailSelector = selector({
  key: `noticeDetailSelector`,
  get: async ({ get }) => {
    if (get(noticeDetailState) === undefined) return;
    let res: ResponseModel<Notice> = await request(APILIST.NOTICE + "/" + get(noticeDetailState), "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(noticeDetailState, object);
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

// 게시물 메인 조회
export const noticeMainSelector = selector({
  key: `noticeMainSelector`,
  get: async ({ get }) => {
    if (get(noticeMainState) === undefined) return;
    let res: ResponseModel<ResponseList<Notice>> = await request(APILIST.NOTICE, "get", get(noticeMainState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(noticeMainState, object);
  },
});

// 팝업 게시물 조회
export const popupSelector = selector({
  key: `popupSelector`,
  get: async ({ get }) => {
    if (get(popUpState) !== undefined) return;
    let res: ResponseModel<Notice[]> = await request(`${APILIST.NOTICE}/popup`, "get", get(popUpState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(popUpState, object);
  },
});





export const stipulationPolicySelector = selector({
  key: `stipulationPolicyState`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/이용약관", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});
export const stipulationPrivacySelector = selector({
  key: `stipulationPrivacyState`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/개인정보처리방침", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const stipulation1Selector = selector({
  key: `stipulationSelector1`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/이용약관", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});
export const stipulation2Selector = selector({
  key: `stipulationSelector2`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/위치기반 서비스 이용약관", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const stipulation3Selector = selector({
  key: `stipulationSelector3`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/개인정보 수집 및 이용 동의 (회원가입)", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const stipulationQR1Selector = selector({
  key: `stipulationQR1Selector`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/전자금융거래 이용약관", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});
export const stipulationQR2Selector = selector({
  key: `stipulationQR2Selector`,
  get: async ({ get }) => {
    let res: ResponseModel<Stipulation> = await request(APILIST.STIPULATION + "/개인정보 수집 및 이용 동의 (비회원 결제)", "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const carCompanySelector = selector({
  key: `carCompanySelector`,
  get: async ({ get }) => {
    let res: ResponseModel<any> = await request(APILIST.CARCOMPANY, "get");
    return res;
  },
  set: ({ set }, object: any) => {
    set(carCompanyState, object);
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const carSelector = selector({
  key: `carSelector`,
  get: async ({ get }) => {
    if (get(carState) === undefined) {
      return;
    }
    const carNm = { nm: get(carState) };
    let res: ResponseModel<any> = await request(APILIST.CAR, "get", carNm);
    return res;
  },
  set: ({ set }, object: any) => {
    set(carState, object);
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const showUserSelector = selectorFamily({
  key: `showUserSelector`,
  get:
    ({ token }: { token: string }) =>
      async ({ get }) => {
        var userId = get(showUserState);
        if (userId === undefined) return;
        let res: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + userId, "get");
        return res;
      },
  set:
    () =>
      ({ set }, object: any) => {
        set(showUserState, object);
      },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});

export const chargingSelector = selector({
  key: `chargingSelector`,
  get: async ({ get }) => {
    if (get(chargingState) === undefined) return;
    let res: ResponseModel<ResponseList<any>> = await request(APILIST.CHARGING, "get", get(chargingState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(chargingState, object);
  },
});

export const findChargerQrSelector = selector({
  key: `findChargerQrSelector`,
  get: async ({ get }) => {
    if (get(variableState) === undefined) return;
    let res: ResponseModel<ResponseList<any>> = await request(APILIST.FIND_CHARGER_QR, "get", get(variableState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const platformSelector = selector({
  key: `platformSelector`,
  get: async ({ get }) => {
    if (get(platformState) === undefined) return;
    let res: ResponseModel<ResponseList<any>> = await request(APILIST.PLATFORM, "get", get(platformState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(platformState, object);
  },
});

export const loginPostSelector = selector({
  key: `loginSelector`,
  get: async ({ get }) => {
    if (get(variableState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.LOGIN, "post", undefined, get(variableState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const accessTokenSelector = selector({
  key: `accessTokenSelector`,
  get: async ({ get }) => {
    if (get(accessTokenState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.USER + "/accessTokenCheck", "get", get(accessTokenState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(accessTokenState, object);
  },
});

export const refreshTokenSelector = selector({
  key: `refreshTokenSelector`,
  get: async ({ get }) => {
    if (get(refreshTokenState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.USER + "/refreshTokenCheck", "get", get(refreshTokenState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(refreshTokenState, object);
  },
});

export const changePasswordPostSelector = selector({
  key: `changePasswordSelector`,
  get: async ({ get }) => {
    if (get(variableState) === undefined) return;
    let res: ResponseModel<ResponseList<any>> = await request(APILIST.UPDATE_PASSWORD, "post", undefined, get(variableState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const changePaycardSelector = selector({
  key: `changePaycardSelector`,
  get: async ({ get }) => {
    if (get(variableState) === undefined) return;
    let res: ResponseModel<ResponseList<any>> = await request(APILIST.UPDATE_PAYCARD, "post", undefined, get(variableState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const installRequestSelector = selector({
  key: `installRequestSelector`,
  get: async ({ get }) => {
    if (get(variableState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.CHARGERREQ, "post", undefined, get(variableState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(variableState, object);
  },
});

export const certifyPhoneSelector = selector({
  key: `certifyPhoneSelector`,
  get: async ({ get }) => {
    if (get(certifyPhoneState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.CERTIFI_PHONE, "post", undefined, get(certifyPhoneState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(certifyPhoneState, object);
  },
});

export const checkAuthSelector = selector({
  key: `checkAuthSelector`,
  get: async ({ get }) => {
    if (get(checkAuthState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.CERTIFI_AUTHCODE, "post", undefined, get(checkAuthState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(checkAuthState, object);
  },
});

// 멤버 회원가입 및 수정도 multipart/form-data 형식으로 변경
export const userRegistSelector = selector({
  key: `userRegistSelector`,
  get: async ({ get }) => {
    if (get(userRegistState) === undefined) return;

    let userInfo: any = get(userRegistState)
    const billingInfo: any = get(smatroReigistBillingState)
    for (const key in billingInfo) {
      if (Object.hasOwnProperty.call(billingInfo, key)) {
        const value = billingInfo[key];
        userInfo.append(key, value)
      }
    }
    const res: ResponseModel<any> = await request(APILIST.REGISTER_USER, "filePost", undefined, undefined, userInfo);
    return res;
  },
  set: ({ set }, object: any) => {
    set(userRegistState, object);
  },
});

export const companyUserRegistSelector = selector({
  key: `companyUserRegistSelector`,
  get: async ({ get }) => {
    if (get(companyUserRegistState) === undefined) return;

    let companyInfo: any = get(companyUserRegistState);
    const billingInfo: any = get(smatroReigistBillingState)
    for (const key in billingInfo) {
      if (Object.hasOwnProperty.call(billingInfo, key)) {
        const value = billingInfo[key];
        companyInfo.append(key, value)
      }
    }
    const res: ResponseModel<any> = await request(APILIST.REGISTER_USER, "filePost", undefined, undefined, companyInfo);
    return res;
  },
  set: ({ set }, object: any) => {
    set(companyUserRegistState, object);
  },
});

export const findPwSelector = selector({
  key: `findPwSelector`,
  get: async ({ get }) => {
    if (get(findPwState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.FINDPW, "get", get(findPwState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(findPwState, object);
  },
});

export const smartroSelector = selector({
  key: `smartroSelector`,
  get: async ({ get }) => {
    if (get(smartroState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.SMARTRO_TEST, "post", undefined, get(smartroState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(smartroState, object);
  },
});

export const smartroRoamingSelector = selector({
  key: `smartroRoamingSelector`,
  get: async ({ get }) => {
    if (get(smartroRoamingState) === undefined) return;
    let res: ResponseModel<any> = await request(APILIST.SMARTRO, "post", undefined, get(smartroRoamingState));
    return res;
  },
  set: ({ set }, object: any) => {
    set(smartroRoamingState, object);
  },
});

export const userChargingSelector = selector({
  key: `userChargingSelector`,
  get: async ({ get }) => {
    if (get(showUserState) === undefined) return;
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + get(showUserState), "get");
    let token: ResponseModel<any> = await request(APILIST.CSMS_KEY, 'post', undefined, { "apiKey": process.env.REACT_APP_CSMS_API_KEY as string }, undefined, undefined, "CSMS");
    if (user != undefined) {
      // let res: ResponseModel<any> = await request(APILIST.CSMS_CHARGING + `/197/chrgr-elctc`, 'token', get(userChargingState), undefined, undefined, `Bearer ${token.data.accessToken}`, "CSMS");
      let res: ResponseModel<any> = await request(APILIST.CSMS_CHARGING + `/${user.data.id}/chrgr-elctc`, 'token', get(userChargingState), undefined, undefined, `Bearer ${token.data.accessToken}`, "CSMS");
      return res;
    }

  },
  set: ({ set }, object: any) => {
    set(userChargingState, object);
  },
});

export const userPointSelector = selector({
  key: `userPointSelector`,
  get: async ({ get }) => {
    const userPoint = get(userPointState);
    if (userPoint?.membership_card_number === undefined) return;
    if (userPoint && userPoint.membership_card_number) {
      let token: ResponseModel<any> = await request(APILIST.POINT_TOKEN, 'post', undefined, { "api_key": process.env.REACT_APP_COUPON_POINT_API_KEY as string }, undefined, undefined, "POINT");
      let res: ResponseModel<any> = await request(APILIST.POINT, 'token', userPoint, undefined, undefined, `Bearer ${token.data.access_token}`, "POINT");
      return res;
    } else {
      // membership_card_number 값이 없거나 undefined인 경우에 대한 처리
      return null;
    }
  },
  set: ({ set }, object: any) => {
    set(userPointState, object);
  },
});

export const userPointListSelector = selector({
  key: `userPointListSelector`,
  get: async ({ get }) => {
    const userPoint = get(userPointListState);
    if (get(showUserState) === undefined) return;
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + get(showUserState), "get");
    let token: ResponseModel<any> = await request(APILIST.POINT_TOKEN, 'post', undefined, { "api_key": process.env.REACT_APP_COUPON_POINT_API_KEY as string }, undefined, undefined, "POINT");
    let res: ResponseModel<any> = await request(APILIST.POINT_LIST + `/${user.data?.cardData?.cardNo}`, 'token', userPoint, undefined, undefined, `Bearer ${token.data.access_token}`, "POINT");

    if (!res) return;

    // 테스트용(포인트 있는 카드번호)
    // let res: ResponseModel<any> = await request(APILIST.POINT_LIST + `/0907130310101006`, 'token', userPoint, undefined, undefined, `Bearer ${token.data.access_token}`, "POINT");
    // console.log(userPoint)
    return res;
  },
  set: ({ set }, object: any) => {
    set(userPointListState, object);
  },
});

export const userExpiresSelector = selector({
  key: `userExpiresSelector`,
  get: async ({ get }) => {
    if (get(showUserState) === undefined) return;
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + get(showUserState), "get");
    let token: ResponseModel<any> = await request(APILIST.POINT_TOKEN, 'post', undefined, { "api_key": process.env.REACT_APP_COUPON_POINT_API_KEY as string }, undefined, undefined, "POINT");
    if (user != undefined) {
      let res: ResponseModel<any> = await request(APILIST.POINT_EXPIRES + `/${user.data.cardData.cardNo}`, 'token', undefined, undefined, undefined, `Bearer ${token.data.access_token}`, "POINT");
      return res;
    }
  },
  set: ({ set }, object: any) => {
    set(userExpiresState, object)
  }
})

export const getUserMembershipGradeSelector = selector({
  key: `getUserMembershipGradeSelector`,
  get: async ({ get }) => {
    if (get(showUserState) === undefined) return;
    console.log(`get(showUserState): ${get(showUserState)}`);
    console.log(`get(showUserState): ${get(showUserState)}`);
    console.log(`get(showUserState): ${get(showUserState)}`);
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + get(showUserState), "get");
    if (user) {
      let res: ResponseModel<any> = await request(APILIST.MEMBERSHIP_USER_GRADE + `/${user.data.id}`, 'get', { cloudCode: 'TD' }, undefined, undefined, undefined, "MEMBERSHIP");
      // let res: ResponseModel<any> = await request(APILIST.MEMBERSHIP_USER_GRADE + `/158`, 'get', { cloudCode: 'TD' }, undefined, undefined, undefined, "MEMBERSHIP");
      return res;
    }
    return {};
  },
  set: ({ set }, object: any) => {
    set(userMembershipGrade, object)
  }
})

export const getJustMembershipGradeSelector = selector({
  key: `getJustMembershipGradeSelector`,
  get: async ({ get }) => {
    if (get(justMembershipGrade) !== undefined) return;
    let res: ResponseModel<any> = await request(APILIST.MEMBERSHIP_JUST_GRADE, 'get', { cloudCode: 'TD' }, undefined, undefined, undefined, "MEMBERSHIP");
    return res;
  },
  set: ({ set }, object: any) => {
    set(justMembershipGrade, object)
  }
})

export const userRepaySelector = selectorFamily({
  key: 'userRepaySelector',
  get: (param: any) => async ({ get }) => {
    if (get(showUserState) === undefined) return;
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + get(showUserState), "get");
    let token: ResponseModel<any> = await request(APILIST.CSMS_KEY, 'post', undefined, { "apiKey": process.env.REACT_APP_CSMS_API_KEY as string }, undefined, undefined, "CSMS");
    if (user != undefined) {
      let res: ResponseModel<any> = await request(APILIST.CSMS_REPAY, 'tokenPost', undefined, { 'userId': user.data.id }, undefined, `Bearer ${token.data.accessToken}`, "CSMS");
      return res;
    }
  },
});

export const userPaidFailSelector = selector({
  key: `userPaidFailSelector`,
  get: async ({ get }) => {
    var userId = get(showUserState);
    if (userId === undefined || userId === null) return;
    let user: ResponseModel<any> = await request(APILIST.SHOWUSER + "/" + userId, "get");
    let token: ResponseModel<any> = await request(APILIST.CSMS_KEY, 'post', undefined, { "apiKey": process.env.REACT_APP_CSMS_API_KEY as string }, undefined, undefined, "CSMS");
    let res: ResponseModel<any> = await request(APILIST.CSMS_PAID_FAIL + `/user/${user.data.id}/paid-fail`, 'token', undefined, undefined, undefined, `Bearer ${token.data.accessToken}`, "CSMS");
    return res;
  },
  set: ({ set }, object: any) => {
    set(userPaidFailState, object);
  },
});

